/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a, a:link, a:visited  {
    text-decoration: none;
}

a:hover  {
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
	font-weight: inherit;
}

ul, ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img, svg {
	max-width: 100%;
	height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

html, body {
  height: 100%;
  margin-top:2,333%;
  background-color: white;
}



@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/Roboto-Regular.ttf')format("truetype"); 
}


@font-face {
  font-family: "Roboto-weight";
  font-style:normal;
  font-weight : bold;
  src: url('./fonts/Roboto-Regular.ttf')format("truetype"); 
}


label {
	cursor: pointer;
}

legend {
	display: block;
}


.App {
  background-color: #fff!important;
  margin-top : 20px
}
.App {
  font-family: 'Roboto';
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}
.arrow-back{
  width: 20px;
  height: 20px;
  background-image: url("./imgs/arrow_back_ios_new.svg");
  background-size: cover;
}
.arrow-forward{
  width: 20px;
  height: 20px;
  background-image: url("./imgs/arrow_back_ios_new.svg");
  background-size: cover;
  transform: rotate(180deg);
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}

.navigation{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
}
.navigation p{
padding-left: 15px;
padding-right: 15px;
font-size:14px
}
.resize_chart{
  margin-top:32px;
}
.legend{
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      align-content: center;
      /* margin-left: 52px; */
      /* margin-right: 52px; */
      width: 100%;
      margin-top: 28px;
      justify-content: space-evenly

    }
.legend p{
  font-size: 14px;
  display: flex;
  align-items: center;
}
.uglevodi{
}
.uglevodi:before{
  content: "";
  margin-right: 8px;
  height: 8px;
  width: 8px;
  background-color: #8D79FF;
  border-radius: 50%;
  display: inline-block;
}

.belki::before {
  content: "";
  margin-right: 8px;
  height: 8px;
  width: 8px;
  background: #79FFBA;
  border-radius: 50%;
  display: inline-block;
}
.zhiri::before
{
  content: "";
  margin-right: 8px;
  height: 8px;
  width: 8px;
  background: #FF79CE;
  border-radius: 50%;
  display: inline-block;
}

.main-table{
  display: flex;
  width: 100%;
  margin-top:32px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  min-height: 100vh;
}
.raw-data{
  display: flex;
  justify-content: space-between
}
.img-product{
  width: 70px;
  height: 70px;

  background-size: cover;
  border-radius: 10px;
  background-repeat: no-repeat;
}
.main-table h{
  font-size:24px;
  font-family: 'Roboto';
  font-weight:1000
}

.table{
  margin-top: 24px;
  height: 100vh;
}
.img-name-cal{
  display: flex;
  width: 65%;
}
.name-calories-product{
  width: 39%;
  margin-left: 10px;
}
.name-calories-product h1{
  font-size: 14px;
}
.name-calories-product p{
  color: #A3ABBE;
  font-size:12px;
}

.edit-delete-product{
}
.btn-edit{
  width: 40px;
  height: 40px;
  background-image: url('./imgs/edit.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.btn-delete{
  width: 40px;
  height: 40px;
  background-image: url('./imgs/delete.svg');
  margin-left: 12px;
  background-repeat: no-repeat;
  background-size: contain;
}

.bar-chart{
  height:6px;
  width:100%;
  margin-top: 8px;
}

.legend-bar-chart{
  display:flex;
  justify-content: space-between;
  font-size:10px;
  margin-top:4px;
}

.legend-bar-chart p{
  color: #A3ABBE
}

.raw-food-data{
  padding-bottom: 20px;
}

/* Исходное состояние элемента */
.fade-slide-enter {
  opacity: 0;
  transform: translateY(-20px);
}

/* Анимация появления элемента */
.fade-slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1000ms ease, transform 1000ms ease;
}

/* Исходное состояние элемента перед удалением */
.fade-slide-exit {
  opacity: 1;
  transform: translateY(0);
  height: auto;
}

/* Анимация удаления элемента */
.fade-slide-exit-active {
  opacity: 0;
  transform: translateY(10px);
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  transition: opacity 100ms ease, transform 100ms ease, height 100ms ease, margin 100ms ease, padding 100ms ease;
}

/* Стили для списка */
.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px 0;
  padding: 10px;
  transition: height 1000ms ease; /* Плавное изменение высоты */
}

.modal-btn{
  width: 100px;
  height: 100px;
  background-color: grey;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.modal {
  display: flex;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 280px;
  margin-top: 42%;
  width: 100%;
  height: 200px;
  top: 226.67px;
  left: 575px;
  gap: 20px;
  border-radius: 10px 10px 10px 10px;
  opacity: 0px;
  flex-direction: column;
  align-content: stretch;
  align-items: normal;
  flex-wrap: nowrap;
  opacity: 1;
}

.close-button {
  top: 10px;
  right: 10px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  background-image: url('./imgs/close.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App {
  overflow: hidden; /* Убираем прокрутку */
  width: 100%;

}

.App-header {
  transition: transform 0.3s ease-out; /* Плавное возвращение на место */
}

.trash-icon{
  width: 50px;
  height: 50px;
}
.modal h{
  font-family :'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 21px;
  font-weight: 600;
}

.modal p1{
  font-size: 14px;
}
.delete-button-modal{
  /* padding: 15px 45px 15px 45px; */
  border-radius: 15px;
}
.delete-button-modal button p{
  color: #000;

}
.close-button-modal{
  padding: 10px 12px 10px 12px;
  border-radius: 15px;
  /* border: 1px solid */
}

.buttons-delete{
  display: flex;
  flex-direction: row-reverse;
  margin-top:24px;
  font-size: 14px;
}
/* 
body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
} */

.modal-backdrop-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
  .input-container {
    position: relative;
    width: 264px;
    margin: 20px 0;
  }

  .input-label {
    position: absolute;
    top: -10px; /* Сдвиг над рамкой */
    left: 10px; /* Отступ слева */
    background: white; /* Фон для перекрытия */
    padding: 0 5px; /* Немного отступов вокруг текста */
    font-size: 14px;
    color: #333;
  }

  .input-value {
    width: 100%;
    font-size: 16px;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 16px;
    outline: none;
  }
  .input-value:focus {
    border-color: black; /* Цвет рамки при фокусе */
  }

  .modal-edit {
  background: white;
  padding: 24px;
  border-radius: 8px;
  max-width: 770px;
  width: 312px;
  height: 488px;
  top: 226.67px;
  left: 575px;
  gap: 20px;
  border-radius: 28px;
  opacity: 1;
  }
  .modal h{
      font-size: 24px;
      line-height: 32px;
  }

  .dish-name{
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
  }   
  input{
      height: 54px;
  } 
  .cancel-accept{
      margin-top: 12%;
      margin-left: 65px;
  }
  .cancel-accept button{
      width: 91px;
      height: 40px;
  }
  .cancel-accept button p {
    font-size: 15px;
  }